import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import { useThemeUI } from "theme-ui"

import PieChart from "../../../../../components/pie-chart"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Small from "../../../../../components/small"
import Stack from "../../../../../components/stack"
import Container from "../../../../../components/container"

const Page = () => {
  const chartData = [
    {
      id: "Verben",
      label: "Verben",
      value: 9639,
      color: "red",
    },
    {
      id: "Nomen",
      label: "Nomen",
      value: 21788,
      color: "hsl(201, 70%, 50%)",
    },
    {
      id: "Adjektive",
      label: "Adjektive",
      value: 1162,
      color: "hsl(180, 70%, 50%)",
    },
  ]
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  const context = useThemeUI()
  const { theme } = context

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/verben"
        />
      }
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Stack>
        <Paragraph>
          Das sind nicht gerade viele. Mit den Adjektiven war Hölderlin sehr
          sparsam.
        </Paragraph>
        <Stack>
          <Heading align="center">Wortarten in Hölderlins Gedichten</Heading>
          <PieChart
            height={["400px", "500px"]}
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 120, left: 0 }}
            enableRadialLabels={false}
            legends={[
              {
                anchor: "bottom",
                direction: "column",
                translateY: 120,
                itemWidth: 220,
                itemHeight: 24,
                itemTextColor: theme.colors["contrast"],
                symbolSize: 18,
                symbolShape: "circle",
              },
            ]}
          />
          <Container maxWidth="sm">
            <Small color="chart" align="center">
              Die Wortarten in Hölderlins Gedichten, gezählt von Heike Gfrereis,
              Vera Hildebrandt und Roland Kamzelak für die Ausstellung
              ›Hölderlin, Celan und die Sprachen der Poesie‹. Daten aus dem
              Deutschen Literaturarchiv Marbach.
            </Small>
          </Container>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
